import React, { useContext, useEffect, useRef, useState } from "react";
import DataProvider from "Context/DataContext";
import axios from "axios";
import { usePostPaymentDetails } from "hooks/Payment/usePostPaymentDetails";
import { usePostPaypalDetails } from "hooks/Payment/usePostPaypalDetails";
import { useForm } from "react-hook-form";
import Loader from "components/loader";
import { navigate } from "gatsby";
import { API_ROOT } from "utils/http";
import CardImage from "assets/images/cc.jpg";
import PaypalExpressBtn from "gatsby-paypal-button";
import moment from "moment";
import FailQuote from "../OnFailComp/FailQuote";
import PaymentImage from "assets/new-assets/paymentimage.png";

function OrderSummaryComp() {
  useEffect(() => {
    if (!localStorage.getItem("order_id")) {
      navigate("/quote");
    }
  }, []);
  const [creditCard, setCreditCard] = useState(false);
  const [hasAPIData, setHasAPIData] = useState(false);
  const [bankTransfer, setBankTransfer] = useState(false);
  const [paypal, setPaypal] = useState(false);
  const [creditCardErrorMessage, setCreditCardErrorMessage] = useState("");
  const [openFailError, setOpenFailError] = useState(false);

  const onSuccess = (payment) => {
    // 1, 2, and ... Poof! You made it, everything's fine and dandy!
    // console.log("Payment successful!", payment);
    // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
    const postData2 = {
      type: "success",
      paypal_info: payment,
      quote_id: thirdStepData?.data?.quote?.id,
    };

    mutateAsync2(postData2).then((res) => {
      setPaymentInfo(res);
      settoggleOrder(true);
      navigate("/quote/success");
      localStorage.removeItem("step");
      localStorage.removeItem("quote_id");
      localStorage.removeItem("order_number");
    });
  };

  const onCancel = (data) => {
    // The user pressed "cancel" or closed the PayPal popup
    // console.log("Payment cancelled!", data);
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
    const postData2 = {
      type: "cancel",
      paypal_info: data,
      quote_id: thirdStepData?.data?.quote?.id,
    };

    mutateAsync2(postData2).then((res) => {});
    setOpenFailError(true);
  };

  const onError = (err) => {
    // The main Paypal script could not be loaded or something blocked the script from loading
    // console.log("Error!", err);
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    const postData2 = {
      type: "error",
      paypal_info: err,
      quote_id: thirdStepData?.data?.quote?.id,
    };

    mutateAsync2(postData2).then((res) => {});
    setOpenFailError(true);
  };

  let env = process.env.GATSBY_PAYPAL_ENV; // you can set this string to 'production'
  let currency = "USD"; // you can set this string from your props or state
  // let env = 'sandbox'; // you can set this string to 'production'
  // let currency = 'USD'; // you can set this string from your props or state
  // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

  const client = {
    sandbox: process.env.GATSBY_PAYPAL_SANDBOX_KEY, //"AeFvXWe80WFSnC-05rT91p2SKjrWD-f1rMkP1NbsEvYLNpZ4EyUm_Rl7gvgwolAaiGpVHzHhRTVOuMn1",
    production: process.env.GATSBY_PAYPAL_PRODUCTION_KEY, //"ASxPkKh2nmxUsRu11rqxBcXiSt3FYDK0zYuxEUqFz6TSizBtS_tEncDrNA1Pc13cXYqyDRHEJdoPq8KB",
  };
  // In order to get production's app-ID, you will have to send your app to Paypal for approval first
  // For your sandbox Client-ID (after logging into your developer account, please locate the "REST API apps" section, click "Create App" unless you have already done so):
  //   => https://developer.paypal.com/docs/classic/lifecycle/sb_credentials/
  // Note: IGNORE the Sandbox test AppID - this is ONLY for Adaptive APIs, NOT REST APIs)
  // For production app-ID:
  //   => https://developer.paypal.com/docs/classic/lifecycle/goingLive/

  // NB. You can also have many Paypal express checkout buttons on page, just pass in the correct amount and they will work!
  // const cardNumber = useRef(null);

  const {
    thirdStepData,
    setThirdStepData,
    setQuoteId,
    quoteId,
    settoggleOrder,
    setPaymentInfo,
  } = useContext(DataProvider);

  useEffect(() => {
    function handleBack() {
      window.history.go(1);
    }
    window.addEventListener("popstate", handleBack);
    const getData = localStorage.getItem("order_id");

    const saveQuoteId = async () => {
      await axios
        .get(
          `${API_ROOT}/api/v1/get-order-info?order_id=${getData.replace(
            "#",
            ""
          )}`
        )
        .then((res) => {
          setQuoteId(res?.data?.data?.quote?.id);

          setThirdStepData(res?.data);
          setHasAPIData(true);
        });
    };

    if (getData && !hasAPIData) {
      saveQuoteId();
    }
    return () => {
      window.removeEventListener("popstate", handleBack);
    };
  }, [quoteId]);

  const { status, mutateAsync } = usePostPaymentDetails();
  const { mutateAsync: mutateAsync2 } = usePostPaypalDetails();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const onCardSubmitButtonClick = () => {
  //   var pub_key = "10001$a7cb7c38a5343a6f733972d46cfcb33fb6e9f3a7234f10aaa3c1279c700525a405e7f027ea38f397b74176207abf19a6f571177b8835ef25b3f4b70b2e4a610fb2fa3a74281f226b0be601d55bf71243e6231b31ea4dd9f35c173742962c0fe8fee76f6c89a5f42a5fc7d56016a9056ff3aaa380a67741f0e553feaed698cd6b233a44c727425ee399c5d57acacefe4bf1eecfd448125a9468adaf6065a6421cc7a371b69a87fda0ab809d08d8117db992de20eb109b3e81f9c0da6ae01b752e42f9662f11d4c11c228f9c4afc58db8eab17e6616844bc219f2373b5ff8c91f0aca824f405609eaa6539e42397a4dfb99ab3b71c6b3ec499ca362c887c0cf68d";
  //   var bluesnap = new window.BlueSnap(pub_key);
  //   bluesnap.encrypt("cc-checkout-form");
  // };

  const onSubmit = (data) => {
    setCreditCardErrorMessage("");

    if (data.cardnumber.length < 12) {
      setCreditCardErrorMessage("Invalid Credit Card number");
      setCreditCardValidated(false);
      return false;
    }
    if (!validateCardNumber(data.cardnumber)) {
      setCreditCardErrorMessage("Invalid Credit Card number");
      setCreditCardValidated(false);
      return false;
    }

    if (
      !cvvValidated ||
      !creditCardValidated ||
      creditCardMonthValidated ||
      creditCardYearValidated
    ) {
      // setCreditCardErrorMessage("There are error in details");
      return false;
    } else {
      var pub_key = process.env.GATSBY_BLUESNAP_PUBLIC_KEY;
      var bluesnap = new window.BlueSnap(pub_key);
      try {
        bluesnap.encrypt("cc-checkout-form");
        var form = document.getElementById("cc-checkout-form");
        var formData = new window.FormData(form);
        var encryptedCreditCard = document.getElementById("cc-checkout-form");
        var form = document.getElementById("cc-checkout-form");

        var formInputs =
          document.forms["cc-checkout-form"].getElementsByTagName("input");
        var encryptedCreditCard = formInputs["encryptedCreditCard"].value;
        var encryptedCvv = formInputs["encryptedCvv"].value;

        const postData = {
          card_number: encryptedCreditCard,
          security_code: encryptedCvv,
          expiration_month: data.months,
          expiration_year: data.years,
          quote_id: quoteId,
        };
        mutateAsync(postData).then((res) => {
          if (res.success === 1) {
            setPaymentInfo(res);
            settoggleOrder(true);
            navigate("/quote/success");
            localStorage.removeItem("step");
            localStorage.removeItem("quote_id");
            localStorage.removeItem("order_id");
          } else {
            setCreditCardErrorMessage(res.message);
            setOpenFailError(true);
          }
        });
      } catch (err) {
        // console.log(err);
        setCreditCardErrorMessage("Errors From Payment Gateway");
      }
    }
  };

  const loadBlueSnapScript = () => {
    const existingScript = document.getElementById("bluesnap-script");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://gateway.bluesnap.com/js/cse/v1.0.4/bluesnap.js";
      script.id = "bluesnap-script";
      script.async = true;
      script.onload = () => {
        // console.log("BlueSnap script loaded");
        // console.log(window.BlueSnap);
      };
      document.body.append(script);
    }
  };

  useEffect(() => {
    loadBlueSnapScript();
  }, []);

  const style = {
    background:
      "transparent linear-gradient(180deg, #F1F6F8 0%, #F9FAFB 61%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
  };

  const [creditCardValidated, setCreditCardValidated] = useState(true);
  const [cvvValidated, setCvvValidated] = useState(true);
  const handleLimit = (e) => {
    setCreditCardValidated(true);
    if (e.target.value.length >= 12) {
      if (!validateCardNumber(e.target.value)) {
        setCreditCardValidated(false);
      }
    }
  };

  const handleCvvLimit = (e) => {
    setCvvValidated(true);
    if (e.target.value.length > 4) {
      setCvvValidated(false);
    }
  };

  const validateCardNumber = (number) => {
    var regex = new RegExp("^[0-9]{8,16}$");
    if (!regex.test(number)) return false;

    return true; //luhnCheck(number);
  };

  const luhnCheck = (val) => {
    var sum = 0;
    for (var i = 0; i < val.length; i++) {
      var intVal = parseInt(val.substr(i, 1));
      if (i % 2 == 0) {
        intVal *= 2;
        if (intVal > 9) {
          intVal = 1 + (intVal % 10);
        }
      }
      sum += intVal;
    }
    return sum % 10;
  };

  const [creditCardMonthValidated, setCreditCardMonthValidated] =
    useState(false);
  const monthArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const cardMonthHandler = (e) => {
    setCreditCardMonthValidated(false);
    if (e.target.value.length >= 2) {
      if (!monthArr.includes(parseInt(e.target.value))) {
        setCreditCardMonthValidated(true);
      }
    }
  };

  const [creditCardYearValidated, setCreditCardYearValidated] = useState(false);
  const yearArr = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];

  const cardYearHandler = (e) => {
    setCreditCardYearValidated(false);
    if (!yearArr.includes(parseInt(e.target.value))) {
      setCreditCardYearValidated(true);
    }
  };

  return (
    <div className="flex items-center justify-center w-full">
      <div className="flex items-center justify-center flex-col w-11/12 md:w-10/12 bg-white gap-y-6 lg:gap-y-12 mt-14 mb-20">
        {/* break */}
        <div className="flex  justify-between flex-wrap lg:flex-nowrap w-full items-baseline">
          <div className="w-11/12 lg:w-6/12">
            <p className="font-semibold text-base leading-[22px] font-opensans text-[#BEBEBE] mt-7 mb-4">
              BILLED BY
            </p>
            <p className="font-bold text-[22px] leading-[30px] font-opensans text-darkBlue mb-1">
              Tomedes Ltd
            </p>

            <p className="text-base leading-[26px] font-opensans text-darkBlue">
              26 HaRokmim st,
            </p>
            <p className="text-base leading-[26px] font-opensans text-darkBlue">
              Azrieli Business Center,
              <br /> Building C, 7th floor, Holon 5885849, Israel
            </p>

            <p className="text-base leading-[26px] font-opensans text-darkBlue">
              <span className="font-semibold">Tel. (US): &nbsp;</span> +1 985
              239 0142
            </p>
            <p className="text-base leading-[26px] font-opensans text-darkBlue">
              <span className="font-semibold"> Tel. (UK): &nbsp;</span> +44 1615
              096140
            </p>
            <p className="text-base leading-[26px] font-opensans text-darkBlue ">
              <span className="font-semibold">Email: &nbsp;</span>
              <a
                href="mailto:support@tomedes.com"
                className="text-lightBlue underline"
              >
                support@tomedes.com
              </a>
            </p>
          </div>
          <div className=" w-11/12 lg:w-5/12">
            <p className="font-semibold text-base leading-[22px] font-opensans text-[#BEBEBE] mt-7 mb-4">
              BILLED TO
            </p>
            <p className="font-opensans text-base leading-[26px]"></p>
            <p>
              <span className="font-semibold text-darkBlue  font-opensans">
                Email: &nbsp;
              </span>
              {thirdStepData?.data?.quote?.customer_email ? (
                <span className="text-lightBlue underline font-opensans">
                  {thirdStepData?.data?.quote?.customer_email}
                </span>
              ) : (
                <span class="h-5 bg-quotetext-100 animate-pulse rounded-md col-span-1 w-6/12 inline-block"></span>
              )}
            </p>
          </div>
        </div>
        {/* break */}
        <div className="flex items-center justify-between flex-wrap lg:flex-nowrap w-full">
          <div className="w-11/12 lg:w-6/12">
            <p className="text-lg font-bold leading-[24px] font-opensans mb-2 flex justify-between sm:justify-start sm:gap-4">
              Project Name :
              <span className="font-normal">
                {thirdStepData?.data?.quote?.project_name ? (
                  thirdStepData?.data?.quote?.project_name
                ) : (
                  <span class="h-5 bg-quotetext-100 animate-pulse rounded-md col-span-1 w-6/12 inline-block"></span>
                )}
              </span>
            </p>
            <p className="text-lg font-bold leading-[24px] font-opensans flex justify-between sm:justify-start sm:gap-10 mb-2 lg:mb-0">
              Invoice no. :{" "}
              <span className="font-normal">
                {thirdStepData?.data?.quote?.invoice_id}
              </span>
            </p>
          </div>
          <div className="w-11/12 lg:w-5/12">
            <p className="text-lg font-bold leading-[24px] font-opensans mb-2 flex justify-between sm:justify-start sm:gap-[4.7rem]">
              Invoice date :{" "}
              <span className="font-normal">
                {moment(thirdStepData?.data?.quote?.invoice_date).format(
                  "MMM DD, YYYY"
                )}
              </span>
            </p>
            <p className="text-lg font-bold leading-[24px] font-opensans flex justify-between sm:justify-start sm:gap-4">
              Invoice expiry date :{" "}
              <span className="font-normal">
                {moment(thirdStepData?.data?.quote?.invoice_expiry).format(
                  "MMM DD, YYYY"
                )}
              </span>
            </p>
          </div>
        </div>
        {/* break */}
        <div className=" w-full flex items-center justify-between lg:flex-nowrap flex-wrap">
          <div className="w-full lg:w-6/12 flex items-center justify-start bg-[#F5F5F5] border border-[#DDDDDD] h-[46px] lg:border-r-0 px-4 lg:px-11">
            {/* <div className="w-full flex items-center justify-between"> */}
            <div className="w-full grid grid-cols-2 items-center lg:flex lg:items-center lg:justify-between ">
              <p className="font-bold font-opensans text-base w-4/12">
                Order No.
              </p>
              {/* <p className="font-bold font-opensans text-base w-8/12">Description</p> */}
              <p className="font-bold font-opensans text-base w-8/12 justify-self-end">
                Description
              </p>
            </div>
          </div>
          <div className="hidden items-center lg:justify-evenly lg:flex justify-between px-4 lg:px-0 w-full lg:w-6/12 bg-[#F5F5F5] border border-[#DDDDDD] h-[46px] lg:border-l-0">
            <p className="font-bold font-opensans text-base">Wordcount</p>
            <p className="font-bold font-opensans text-base">Price/Word</p>
            <p className="font-bold font-opensans text-base">Total</p>
          </div>
        </div>
        {/* break */}
        <div className=" w-full flex items-center justify-between lg:flex-nowrap flex-wrap border-b border-[#DDDDDD] lg:-mt-7 pb-6">
          <div className="w-full lg:w-6/12 flex items-center justify-center px-4 lg:px-11">
            <div className="w-full flex md:items-center justify-between items-center">
              <p className="leading-[22px] font-opensans text-base w-4/12">
                {thirdStepData?.data?.quote?.order_number}
              </p>
              <p className="font-normal leading-[26px] font-opensans text-[13px] ml-3 sm:ml-0 sm:text-base w-8/12 break-words">
                Language Pair: {thirdStepData?.data?.quote?.source_language} -{" "}
                {thirdStepData?.data?.quote_items?.map(
                  ({ target_language }) => (
                    <span>
                      {target_language}{" "}
                      {thirdStepData?.data?.quote_items?.length > 1
                        ? ","
                        : null}
                    </span>
                  )
                )}{" "}
                <br /> Service Type:{" "}
                {thirdStepData?.data?.quote?.service_type === 2
                  ? "Fast Delivery"
                  : thirdStepData?.data?.quote?.service_type === 1
                  ? "Best Price"
                  : null}
              </p>
            </div>
          </div>
          {/* break */}
          <div className="flex lg:hidden my-5  items-center  lg:justify-evenly justify-between px-4 lg:px-0 w-full lg:w-6/12 bg-[#F5F5F5] border border-[#DDDDDD] h-[46px] lg:border-l-0">
            <p className="font-bold font-opensans text-base">Wordcount</p>
            <p className="font-bold font-opensans text-base">Price/Word</p>
            <p className="font-bold font-opensans text-base">Total</p>
          </div>
          <div className="flex items-center lg:justify-evenly justify-between px-4 lg:px-0 w-full lg:w-6/12">
            <p className="leading-[22px] font-opensans text-base">
              {thirdStepData?.data?.quote?.total_words ? (
                thirdStepData?.data?.quote?.total_words
              ) : (
                <span class="h-5 bg-quotetext-100 animate-pulse rounded-md col-span-1 w-6/12 inline-block"></span>
              )}
            </p>
            <p className="leading-[22px] font-opensans text-base">
              {thirdStepData?.data?.quote?.service_type === 2
                ? thirdStepData?.data?.quote?.price_per_word_best_time
                : thirdStepData?.data?.quote?.service_type === 1
                ? thirdStepData?.data?.quote?.price_per_word_best_price
                : null}
            </p>
            <p className="leading-[22px] font-opensans text-base">
              {thirdStepData?.data?.quote?.final_price_before_discount}
            </p>
          </div>
        </div>
        {/* break */}
        <div className="w-full flex items-center lg:justify-end justify-center">
          <div className="flex items-center justify-center flex-col w-full lg:w-4/12 gap-y-5">
            <div className="flex items-center justify-between w-full">
              <p className="font-opensans text-base leading-[26px]">
                Sub Total
              </p>
              <p className="font-opensans text-base leading-[26px]">
                {thirdStepData?.data?.quote?.final_price_before_discount}
              </p>
            </div>
            {thirdStepData?.data?.quote?.coupon_code && (
              <>
                <div className="flex items-center justify-between w-full">
                  <p className="font-opensans text-base leading-[26px]">
                    Discount{" "}
                    {thirdStepData?.data?.quote?.coupon_code
                      ? `(${thirdStepData?.data?.quote?.coupon_code})`
                      : null}
                  </p>
                  <p className="font-opensans text-base leading-[26px]">
                    -{" "}
                    {thirdStepData?.data?.quote?.coupon_amount
                      ? thirdStepData?.data?.quote?.coupon_amount
                      : `00.00`}
                  </p>
                </div>
              </>
            )}
            {thirdStepData?.data?.quote?.tax_amount && (
              <>
                <div className="flex items-center justify-between w-full">
                  <p className="font-opensans text-base leading-[26px]">
                    Tax Amount:
                  </p>
                  <p className="font-opensans text-base leading-[26px]">
                    +{" "}
                    {thirdStepData?.data?.quote?.tax_amount
                      ? thirdStepData?.data?.quote?.tax_amount
                      : `00.00`}
                  </p>
                </div>
              </>
            )}
            <div className="flex items-center justify-between w-full">
              <p className="font-bold text-[24px] leading-[26px] font-opensans">
                Total{" "}
              </p>
              <p className="font-bold text-[24px] leading-[26px] font-opensans">
                {thirdStepData?.data?.quote?.final_price_after_tax ? (
                  thirdStepData?.data?.quote?.final_price_after_tax
                ) : (
                  <span class="h-5 bg-quotetext-100 animate-pulse rounded-md col-span-1 w-6/12 inline-block"></span>
                )}
              </p>
            </div>
          </div>
        </div>
        {/* break */}
        <div className=" w-full flex items-center justify-center font-opensans text-[26px] leading-[55px] lg:h-[79px] h-[45px] font-bold bg-[#F5F5F5] border border-[#DDDDDD] relative">
          Payment Options
          <FailQuote open={openFailError} setOpen={setOpenFailError} />
        </div>

        {/* break */}
        <div className="flex items-center justify-center flex-col w-full gap-y-5">
          <div className="w-full flex items-center justify-center flex-col">
            <div
              className={`${
                creditCard
                  ? "border-b-0 border-2 border-black"
                  : "border border-[#DDDDDD]"
              } cursor-pointer w-full lg:w-8/12 h-[79px]  flex items-center flex-wrap sm:flex-nowrap justify-between lg:px-12`}
            >
              <div
                className="flex w-full sm:w-6/12 items-center"
                onClick={() => {
                  setBankTransfer(false);
                  setCreditCard(!creditCard);
                  setPaypal(false);
                }}
              >
                <div
                  className={`${
                    creditCard
                      ? "border-orange border-4 w-[20px] h-[20px]"
                      : "border-[#DDDDDD] border-2 w-[25px] h-[25px]"
                  }  rounded-full ml-3 lg:ml-0   mr-5`}
                ></div>
                <p className="font-semibold text-xl leading-[55px] font-opensans">
                  Pay using Credit Card
                </p>
              </div>
              <div className="w-full sm:w-6/12">
                <img
                  src={PaymentImage}
                  alt="loading..."
                  className="h-[34px] sm:w-[220px] hidden sm:block ml-auto"
                />
              </div>
            </div>

            {/* break child */}
            <form
              id="cc-checkout-form"
              className={`${
                creditCard ? "flex border-2 border-black" : "hidden "
              }  items-center justify-center py-6 flex-col gap-y-6 w-full  lg:w-8/12`}
              onSubmit={handleSubmit(onSubmit)}
            >
              <label
                className="flex flex-col justify-center items-center w-11/12"
                htmlFor="creditCard"
              >
                <span className="text-left w-full font-semibold text-lg leading-[22px] font-opensans">
                  Card number
                </span>
                <input
                  type="number"
                  data-bluesnap="encryptedCreditCard"
                  name="cardnumber"
                  {...register("cardnumber", { required: true })}
                  onChange={handleLimit}
                  className="w-full h-[50px] number_input text-black border rounded-md mt-2 text-lg leading-[22px] border-quotetext-100 outline-none px-8 focus:border-red"
                  placeholder="0000-0000-0000-0000"
                />
                {errors.cardnumber && (
                  <span className="text-xs font-opensans font-normal text-left w-full text-red">
                    This field is required.
                  </span>
                )}
                {!creditCardValidated && (
                  <span className="text-xs font-opensans font-normal text-left w-full text-red">
                    Invalid card details.
                  </span>
                )}
              </label>

              <div className="flex items-center gap-5 w-11/12 md:ml-0 ml-[10px]   md:justify-start justify-center">
                <label className="flex flex-col relative">
                  <span className="text-left w-full font-semibold text-lg leading-[22px] font-opensans">
                    Expiration
                  </span>
                  <input
                    type="number"
                    name="months"
                    {...register("months", { required: true })}
                    placeholder="MM"
                    onKeyUp={cardMonthHandler}
                    className={`${
                      errors.months ? "border-red" : "border-quotetext-100"
                    } number_input rounded-md text-black border mt-2 outline-none text-center w-full max-w-[161px] h-[50px]`}
                  />
                  {creditCardMonthValidated && (
                    <span className="text-xs font-opensans font-normal text-left w-full text-red absolute bottom-[-1.25rem]">
                      Invalid Month
                    </span>
                  )}
                  {errors.months && (
                    <span className="text-xs font-opensans hidden sm:block font-normal text-left w-full text-red">
                      This field is required.
                    </span>
                  )}
                </label>
                <label className="flex flex-col relative">
                  <span className="text-left w-full font-semibold text-lg h-[31px] leading-[22px] font-opensans"></span>
                  <input
                    type="number"
                    name="years"
                    placeholder="YYYY"
                    {...register("years", { required: true })}
                    onKeyUp={cardYearHandler}
                    className={`${
                      errors.years ? "border-red" : "border-quotetext-100"
                    }  number_input rounded-md text-black border  outline-none text-center w-full max-w-[161px] h-[50px]`}
                  />
                  {creditCardYearValidated && (
                    <span className="text-xs font-opensans font-normal text-left w-full text-red absolute bottom-[-1.25rem]">
                      Invalid Year
                    </span>
                  )}
                  {errors.years && (
                    <span className="text-xs font-opensans hidden sm:block font-normal text-left w-full text-red">
                      This field is required.
                    </span>
                  )}
                </label>
                <label className="flex flex-col relative">
                  <span className="text-left w-full font-semibold text-lg leading-[22px] font-opensans">
                    CVV
                  </span>
                  <input
                    type="number"
                    name="cvv"
                    data-bluesnap="encryptedCvv"
                    {...register("cvv", { required: true })}
                    placeholder="e.g. 123"
                    onKeyUp={handleCvvLimit}
                    className={`${
                      errors.cvv ? "border-red" : "border-quotetext-100"
                    }  number_input rounded-md text-black mt-2 border  outline-none text-center  w-full max-w-[161px] h-[50px]`}
                  />
                  {errors.cvv && (
                    <span className="text-xs font-opensans hidden sm:block font-normal text-left w-full text-red">
                      This field is required.
                    </span>
                  )}
                  {!cvvValidated && (
                    <span className="text-xs font-opensans font-normal text-left w-full text-red absolute bottom-[-1.25rem]">
                      Invalid CVV
                    </span>
                  )}
                </label>
              </div>
              <label className="flex flex-col justify-center items-center w-11/12">
                <span className="text-left w-full font-semibold text-lg leading-[22px] font-opensans">
                  Name on Card
                </span>
                <input
                  type="text"
                  name="name"
                  {...register("ccName", { required: true })}
                  className="chName w-full h-[50px] mt-2 text-black border rounded-md border-quotetext-100 outline-none px-8"
                  placeholder="Name"
                  pattern="^[a-zA-Z\s]*$"
                  title="Please enter alphabet only"
                />
                {errors.ccName && (
                  <span className="text-xs font-opensans hidden sm:block font-normal text-left w-full text-red">
                    This field is required.
                  </span>
                )}
              </label>
              <label className="flex w-11/12 items-center text-sm justify-center md:justify-start">
                <input
                  type="checkbox"
                  className={`${errors.check ? "border-red" : ""} w-[20px]`}
                  name="check"
                  {...register("check", { required: true })}
                />
                <span className="  text-black ml-4 text-base font-semibold leading-[28px] font-opensans">
                  I agree to the{" "}
                  <span className="text-[#3A7AFF]">
                    <a href="/user-agreement" target="_blank">
                      Terms & Conditions{" "}
                    </a>
                  </span>
                  of Tomedes
                </span>
              </label>
              <label className="flex flex-col justify-center items-center w-11/12">
                {errors.check && (
                  <span className="text-xs font-opensans hidden sm:block font-normal text-left w-full text-red">
                    This field is required.
                  </span>
                )}
              </label>
              <p className="text-base font-opensans text-center text-red">
                {creditCardErrorMessage ? creditCardErrorMessage : ""}
              </p>
              <button
                type="submit"
                className="bg-orange text-white w-11/12 h-[59px] font-arial font-bold text-[22px] leading-[23px]"
              >
                {status === "loading" ? <Loader /> : "Pay now"}
              </button>
            </form>
            {/* break child */}
          </div>
          <div className="w-full flex items-center justify-center flex-col">
            <div
              className={`${
                paypal
                  ? "border-b-0 border-2 border-black"
                  : "border border-[#DDDDDD]"
              } cursor-pointer w-full lg:w-8/12 h-[79px]  flex items-center flex-wrap sm:flex-nowrap justify-between lg:px-12`}
            >
              <div
                className="flex w-full lg:w-6/12 items-center"
                onClick={() => {
                  setBankTransfer(false);
                  setCreditCard(false);
                  setPaypal(!paypal);
                }}
              >
                <div
                  className={`${
                    paypal
                      ? "border-orange border-4 w-[20px] h-[20px]"
                      : "border-[#DDDDDD] border-2 w-[25px] h-[25px]"
                  }  ml-3 lg:ml-0  rounded-full mr-5`}
                ></div>
                <p className="font-semibold text-xl leading-[55px] font-opensans">
                  Paypal
                </p>
              </div>
              <div className="flex w-full justify-end  sm:w-6/12 -mt-16 sm:mt-0">
                <img
                  src="https://www.paypalobjects.com/digitalassets/c/website/marketing/na/us/logo-center/9_bdg_secured_by_pp_2line.png"
                  border="0"
                  alt="Secured by PayPal"
                />
              </div>
            </div>
            <div
              className={`${
                paypal ? "border-2 border-black flex" : "hidden"
              } w-full lg:w-8/12 sm:p-10 p-5 h-[100px]  items-center sm:justify-start justify-center`}
            >
              {thirdStepData?.data?.quote?.payment_amount && (
                <PaypalExpressBtn
                  env={env}
                  client={client}
                  currency={currency}
                  total={thirdStepData?.data?.quote?.payment_amount}
                  onError={onError}
                  onSuccess={onSuccess}
                  onCancel={onCancel}
                />
              )}
            </div>
          </div>
          <div className="w-full flex items-center justify-center flex-col">
            <div
              className="border-2 border-[#DDDDDD]
             w-full lg:w-8/12 h-auto px-8 flex items-start flex-col flex-wrap lg:px-12
            >"
            >
              <p className="text-[16px] font-semibold font-sans text-black pt-[30px]">{`We accept all payments methods (Bank Transfer, Bitcoin, Digital Payment)`}</p>
              <p className="text-[16px] font-sans text-black pt-[16px] pb-[30px]">
                Call us on <span className="text-orange">+1 985 239 0142</span>{" "}
                or email us at{" "}
                <span className="text-orange">support@tomedes.com.</span>{" "}
              </p>
            </div>
          </div>

          {/* <div className="w-full flex items-center justify-center flex-col ">
            <div
              className={`${
                bankTransfer ? "border-b-0 border-2 border-black" : "border border-[#DDDDDD]"
              } cursor-pointer w-full lg:w-8/12 h-[79px]  flex items-center flex-wrap sm:flex-nowrap justify-between lg:px-12`}
            >
              <div
                className="flex w-full sm:w-6/12 items-center"
                onClick={() => {
                  setBankTransfer(false);
                  setCreditCard(false);
                  setPaypal(false);
                }}
              >
                <div className={`${bankTransfer ? "border-orange border-4 w-[20px] h-[20px]" : "border-[#DDDDDD] border-2 w-[25px] h-[25px]"}  rounded-full ml-3 lg:ml-0  mr-5`}></div>
                <p className="font-semibold text-xl leading-[55px] font-opensans">Bank Wire Transfer (soon)</p>
              </div>
            </div>
            <div className={`${bankTransfer ? "border-2 border-black flex" : "hidden"} w-full lg:w-8/12 sm:p-10 p-5   items-center sm:justify-start justify-center`}>
              <div className="flex items-center justify-center flex-col w-full sm:w-10/12 gap-y-5">Coming Soon</div>
              {/* <div className="flex items-center justify-center flex-col w-full sm:w-10/12 gap-y-5">
                 <div className="flex w-full items-center justify-between">
                   <p  className="font-opensans font-bold text-lg leading-[26px]">Bank:</p>
                   <p className="font-opensans font-semibold text-lg leading-[26px]">Bank Name Here</p>
                 </div>
                 <div className="flex w-full items-center justify-between">
                   <p  className="font-opensans font-bold text-lg leading-[26px]">Account name:</p>
                   <p className="font-opensans font-semibold text-lg leading-[26px]">Account name here</p>
                 </div>
                 <div className="flex w-full items-center justify-between">
                   <p  className="font-opensans font-bold text-lg leading-[26px]">Account number:</p>
                   <p className="font-opensans font-semibold text-lg leading-[26px]">XXXX-XXXX-XXX</p>
                 </div>
                 <div className="flex w-full items-center justify-between">
                   <p  className="font-opensans font-bold text-lg leading-[26px]">Bank address:</p>
                   <p className="font-opensans font-semibold text-lg leading-[26px]">Bank address here</p>
                 </div>
                 <div className="flex w-full items-center justify-start">
                   <p  className="font-opensans font-semibold text-lg leading-[26px]"> *All bank fees are at the client's expense.</p>
                 </div>
               </div>
            </div>
          </div> 
          */}
        </div>
      </div>
    </div>
  );
}

export default OrderSummaryComp;
