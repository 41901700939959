import React from "react";
import OrderSummaryComp from "components/Quote/QuoteReusableComp/OrderSummaryComp";
import Head from "components/Quote/Quotelayout/Head";
import QuoteFooter from "components/Quote/Quotelayout/QuoteFooter";

function Index() {
  return (
    <div className="overflow-x-hidden">
      <Head />
      <OrderSummaryComp />
      <QuoteFooter />
    </div>
  );
}

export default Index;
