import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function FailQuote({ open, setOpen }) {
  return (
    <>
      {open && (
        <div className="w-screen bg-[#F35757] text-white py-7 sm:py-4 fixed top-0 z-50">
          <div className="w-11/12 md:w-10/12 2xl:w-9/12 mx-auto max-w-6xl font-opensans text-17 text-white flex">
            <p className="text-[12px] sm:text-[15px] md:text-17 font-normal leading-5 sm:leading-9 mr-3 sm:mr-10">
              We're sorry, we were unable to process your payment. Please try again later. If you need help, please contact us at &nbsp;
              <a href="mailto:support@tomedes.com" class="font-semibold no-underline">
                support@tomedes.com
              </a>
            </p>
            <div>
              <div className="w-8 h-8 bg-white text-[#F35757] text-center flex items-center justify-center text-xl rounded-full cursor-pointer" onClick={() => setOpen(false)}>
                <FontAwesomeIcon icon={faTimes} className="" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
