import React from "react";

function QuoteFooter() {
  return (
    <section className="bg-black text-center w-full py-7">
      <p className="text-[16px] font-opensans text-[#D8DAE1]">
        Need help with your order? Contact us at &nbsp;
        <a href="mailto:support@tomedes.com" className="font-semibold no-underline">
          support@tomedes.com
        </a>
      </p>
    </section>
  );
}

export default QuoteFooter;
