import { useMutation, useQueryClient } from "react-query";
import { endpoints } from "utils/endpoints";
import http from "utils/http";

const PostPayment = (postData) => {
  return http().post(endpoints.payment.payment_by_card, postData);
};

export function usePostPaymentDetails() {
  const queryClient = useQueryClient();
  return useMutation(PostPayment, {
    onSuccess: () => {
      queryClient.invalidateQueries(["PostPayment"]);
    },
    onError: (errorData) => {
      const errorMessage = errorData?.error?.message || errorData?.message;
      alert(errorMessage);
    },
  });
}