import React from 'react';
import { Link } from 'gatsby';
import OptimizedImage from 'hooks/OptimizedImage';

function Head() {
    return (
        <div className="flex items-center justify-center bg-black">
            <div className="max-w-screen-xl flex items-center justify-between w-full py-5 px-3">
                <div>
                    <Link to="/">
                        <OptimizedImage src="https://tomedes.gumlet.io/assets/images/Tomedes_Logo.svg" alt="logo" className="md:w-48 w-[110px] h-[22px] md:h-10" />
                    </Link>
                </div>
                <div>
                    <div className="flex flex-col items-center justify-center">
                        <p className="text-white md:text-base text-sm font-light font-secondary w-full md:text-left text-right">Need help? <Link to="/contactus.php" className='underline underline-offset-4 '> Contact us </Link>.</p>
                        <h2 className="text-xs font-secondary font-normal text-quotetext-500 w-full text-right">US: +1 985 239 0142 | <span className="md:inline-block hidden">UK: +44 1615 096140</span></h2>
                        <p className="md:hidden block text-xs font-secondary font-normal text-quotetext-500 w-full text-right">UK: +44 1615 096140</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Head
